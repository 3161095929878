import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "~/components/layout"
import HeroSection from "~/components/sections/internships/hero"
import SEO from "~/components/seo"

const InternshipsPage: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Internships" />
    <HeroSection />
  </Layout>
)

export default InternshipsPage
