import { graphql, useStaticQuery } from "gatsby"

const useInternshipOpportunities = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicInternshipOpportunity {
        edges {
          node {
            data {
              description {
                text
              }
              link {
                url
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  `)

  return data.allPrismicInternshipOpportunity.edges
}

export default useInternshipOpportunities
