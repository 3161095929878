// extracted by mini-css-extract-plugin
export var background = "index-module--background--5ae68";
export var background__img = "index-module--background__img--e150d";
export var info = "index-module--info--156c3";
export var infoWrapper = "index-module--infoWrapper--9f307";
export var info__headline__desktop = "index-module--info__headline__desktop--0104a";
export var info__headline__mobile = "index-module--info__headline__mobile--a5003";
export var info__text = "index-module--info__text--683ae";
export var main = "index-module--main--a290a";
export var main__companies = "index-module--main__companies--e3918";
export var main__cta = "index-module--main__cta--37844";
export var main__lists = "index-module--main__lists--1ccce";
export var main__logos = "index-module--main__logos--b1b91";
export var main__title = "index-module--main__title--27255";
export var root = "index-module--root--1fbd1";
export var testimonial__desktop = "index-module--testimonial__desktop--32e5d";
export var testimonial__mobile = "index-module--testimonial__mobile--046ca";
export var wrapper = "index-module--wrapper--5789b";