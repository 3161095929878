import { graphql, useStaticQuery } from "gatsby"

const useInternshipTestimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicInternshipTestimonial(
        sort: { fields: first_publication_date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            data {
              picture {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
                alt
              }
              name {
                text
              }
              position {
                text
              }
              text {
                text
              }
              university {
                text
              }
            }
          }
        }
      }
    }
  `)

  return data.allPrismicInternshipTestimonial.edges
}

export default useInternshipTestimonials
